<template>
  <div class="page">
    <img src="./img/success.png" alt="" />
    <div class="txt">预约成功</div>
    <div class="btn1" @click="goPath('universityForTheElderly')">继续预约</div>
    <div class="btn2" @click="goPath('myAppointment')">查看预约</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goPath(name) {
      this.$router.replace({
        name: name,
      });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 82px 0 0;
  .btn1 {
    position: fixed;
    width: 620px;
    height: 74px;
    border-radius: 10px;
    border: 2px solid #93ccf1;
    text-align: center;
    line-height: 74px;
    font-size: 34px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #93ccf1;
    left: 50%;
    bottom: 216px;
    transform: translateX(-50%);
  }
  .btn2 {
    text-align: center;
    line-height: 74px;
    width: 620px;
    height: 74px;
    background: #93ccf1;
    border-radius: 10px;
    font-size: 34px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
  }
  .txt {
    font-size: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3a9afb;
    text-align: center;
  }
  img {
    width: 594px;
    margin: 0 auto 38px;
    display: block;
  }
}
</style>